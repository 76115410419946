import { graphql } from "gatsby"
import {
  FaFacebookF,
  FaExternalLinkAlt,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SplitTile from "../components/splitTile"

/** @jsx jsx */
import { jsx, Box, Button, Divider, Heading, Text, Styled } from "theme-ui"

import federweltCover from "../images/presse/aktuelles_federwelt-cover.jpg"
import mediadatenPdf from "../content/misc/mediadaten_uschtrin.pdf"
import selfpublisherCover from "../images/presse/aktuelles_selfpublisher-cover.jpg"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      title="Zeitschriften"
      location={location}
      descrption="Unsere beiden Zeitschriften"
    />
    <Heading as="h1" variant="siteTitle">
      Unsere Zeitschriften
    </Heading>
    <SplitTile
      image={data.federwelt.childImageSharp.fluid}
      imageInfo="Cover der aktuellen Federwelt-Ausgabe"
    >
      <Heading as="h2" sx={{ fontSize: 4, fontWeight: 900 }}>
        Federwelt
      </Heading>
      <p sx={{ fontSize: 4, fontWeight: "bold" }}>
        Zeitschrift für Autorinnen und Autoren
      </p>
      <a
        href="http://www.autorenwelt.de/magazin/federwelt/aktuelles-heft"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>
          Weitere Informationen <FaExternalLinkAlt sx={{ fontSize: 14 }} />
        </Button>
      </a>
    </SplitTile>
    <Box>
      <Text variant="default">
        Die Fachzeitschrift aus dem Uschtrin Verlag wendet sich an
        SchreibanfängerInnen und Textprofis. Sie enthält praxisnahe Fachartikel
        und Interviews zum Thema Schreiben und Veröffentlichen von und mit
        Insidern aus der Branche, Nachrichten, Terminkalender u.v.m.
      </Text>
      <Text variant="default">
        Chefredakteurin: Anke Gasch,{" "}
        <Styled.a href="mailto:anke.gasch@federwelt.de">
          anke.gasch@federwelt.de
        </Styled.a>
      </Text>
      <Text variant="default">
        Sie möchten in der Federwelt{" "}
        <span style={{ fontWeight: "bold" }}>Anzeigen schalten?</span> Hier geht
        es zu unseren <Styled.a href={mediadatenPdf}>Mediadaten 2025</Styled.a>.
      </Text>
      <Text variant="default">
        Weitere Infos zum Heft und zum Abo (6 Hefte/57 EUR) - aktuelle Ausgabe,
        Heftarchiv, Leseproben, Federwelt-Artikel, Bestellmöglichkeit usw. -
        finden Sie auf den{" "}
        <Styled.a
          href="https://www.autorenwelt.de/magazin/federwelt/aktuelles-heft"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seiten der Autorenwelt
        </Styled.a>{" "}
        <FaExternalLinkAlt sx={{ fontSize: 14 }} />.
      </Text>
      <Text variant="default" sx={{ pt: 3 }}>
        <a
          href="https://www.facebook.com/federwelt.zeitschrift"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link zur Facebook-Seite der Federwelt"
        >
          <FaFacebookF sx={{ fontSize: 4, color: "text" }} />
        </a>
        <a
          href="https://twitter.com/DieFederwelt"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link zum Twitter-Account der Federwelt"
        >
          <FaTwitter sx={{ fontSize: 4, ml: 3, color: "text" }} />
        </a>
        <a
          href="https://www.instagram.com/federwelt.zeitschrift/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link zum Instagram-Account der Federwelt"
        >
          <FaInstagram sx={{ fontSize: 4, ml: 3, color: "text" }} />
        </a>
      </Text>
    </Box>
    <Divider />
    <SplitTile
      image={data.selfpublisher.childImageSharp.fluid}
      inverted={true}
      imageInfo="Cover der aktuellen Selfpublisher-Ausgabe"
    >
      <Heading as="h2" sx={{ fontSize: 4, fontWeight: 900 }}>
        der selfpublisher
      </Heading>
      <a
        href="http://www.autorenwelt.de/magazin/derselfpublisher/aktuelles-heft"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>
          Weitere Informationen <FaExternalLinkAlt sx={{ fontSize: 14 }} />
        </Button>
      </a>
    </SplitTile>
    <Box>
      <Text variant="default">
        Selfpublisher sind immer auf der Suche. Nach neuen Lesern. Nach Infos,
        wie sie ihre Bücher noch attraktiver machen. Und nach Dienstleistern,
        die ihnen helfen, diese Ziele erfolgreich umzusetzen.
      </Text>
      <Text variant="default">
        Mit dem neuen Magazin der selfpublisher bringen wir zusammen, was
        zusammengehört: Selfpublisher und Dienstleister. Interviews, Analysen
        und Umfragen dienen der Orientierung. Anzeigen bieten Dienstleistern die
        Chance, sich fachgerecht und zielgenau zu präsentieren.
      </Text>
      <Text variant="default">
        Redaktion:{" "}
        <Styled.a href="mailto:info@uschtrin.de">info@uschtrin.de</Styled.a>
        <br />
        Herausgeber:{" "}
        <Styled.a
          href="https://www.selfpublisher-verband.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Selfpublisher-Verband e.V.
        </Styled.a>{" "}
        <FaExternalLinkAlt sx={{ fontSize: 14 }} />
      </Text>
      <Text variant="default">
        Sie möchten im selfpublisher Anzeigen schalten? Hier geht es zu den{" "}
        <Styled.a href={mediadatenPdf}>Mediadaten 2025</Styled.a>.
      </Text>
      <Text variant="default">
        Weitere Infos zum Heft und zum Abo (4 Hefte/36 EUR) finden Sie jetzt auf
        auf den{" "}
        <Styled.a
          href="https://www.autorenwelt.de/magazin/derselfpublisher/aktuelles-heft"
          target="_blank"
          rel="noopener noreferrer"
        >
          Seiten der Autorenwelt
        </Styled.a>{" "}
        <FaExternalLinkAlt sx={{ fontSize: 14 }} />.
      </Text>
      <Text variant="default" sx={{ pt: 3 }}>
        <a
          href="https://www.facebook.com/derselfpublisher"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link zur Facebook-Seite des selfpublisher"
        >
          <FaFacebookF sx={{ fontSize: 4, color: "text" }} />
        </a>
        <a
          href="https://twitter.com/derselfpublish"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link zum Twitter-Account des selfpublisher"
        >
          <FaTwitter sx={{ fontSize: 4, ml: 3, color: "text" }} />
        </a>
      </Text>
    </Box>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Periodical",
        "@id": "https://www.autorenwelt.de/magazin/federwelt/aktuelles-heft#periodical",
        "url": "https://www.uschtrin.de/zeitschriften",
        "sameAs": ["https://www.autorenwelt.de/magazin/federwelt/aktuelles-heft","https://de.wikipedia.org/wiki/Federwelt", "https://zdb-katalog.de/title.xhtml?idn=022592857"],
        "name": "Federwelt - Zeitschrift für Autorinnen und Autoren",
        "abstract": "Die Fachzeitschrift aus dem Uschtrin Verlag wendet sich an SchreibanfängerInnen und Textprofis. Sie enthält praxisnahe Fachartikel und Interviews zum Thema Schreiben und Veröffentlichen von und mit Insidern aus der Branche, Nachrichten, Terminkalender u.v.m.",
        "issn": "	1439-8362",
        "inLanguage": "German",
        "publisher": {
          "@type": "Organization",
          "@id": "https://www.uschtrin.de#organisation",
          "name": "Uschtrin Verlag"
        },
        "editor": {
          "@type": "Person",
          "givenName": "Anke",
          "familyName":"Gasch",
          "sameAs": ["https://www.frohes-schreiben.de/about.html", "https://www.autorenwelt.de/person/anke-gasch"]
        },
        "image": "https://www.uschtrin.de${federweltCover}"
      }`}
    </script>
    <script type="application/ld+json">
      {`{
        "@context": "https://schema.org",
        "@type": "Periodical",
        "@id": "https://www.autorenwelt.de/magazin/derselfpublisher/aktuelles-heft#periodical",
        "url": "https://www.uschtrin.de/zeitschriften",
        "sameAs": ["https://www.autorenwelt.de/magazin/derselfpublisher/aktuelles-heft","https://zdb-katalog.de/title.xhtml?idn=1079939059"],
        "name": "Mit dem neuen Magazin der selfpublisher bringen wir zusammen, was zusammengehört: Selfpublisher und Dienstleister. Interviews, Analysen und Umfragen dienen der Orientierung. Anzeigen bieten Dienstleistern die Chance, sich fachgerecht und zielgenau zu präsentieren.",
        "issn": "	2365-0389",
        "inLanguage": "German",
        "publisher": {
          "@type": "Organization",
          "@id": "https://www.uschtrin.de#organisation",
          "name": "Uschtrin Verlag"
        },
        "editor": {
          "@type": "Person",
          "name": "Selfpublisher-Verband e.V.",
          "sameAs": "https://www.selfpublisher-verband.de/"
        },
        "image": "https://www.uschtrin.de${selfpublisherCover}"
      }`}
    </script>
  </Layout>
)

export const query = graphql`
  query {
    federwelt: file(
      relativePath: { eq: "presse/aktuelles_federwelt-cover.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    selfpublisher: file(
      relativePath: { eq: "presse/aktuelles_selfpublisher-cover.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 512) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
